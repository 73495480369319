<div>
  <span class="tc-dust font-14px">Last updated 3 months ago</span>
  <div class="mt-24 d-flex f-column gap-16">
    <app-roam-input [name]="'Current Password'"></app-roam-input>
    <app-roam-input [name]="'New Password'"></app-roam-input>
    <app-roam-input [name]="'Confirm Password'"></app-roam-input>
  </div>
  <hr class="mt-24 mb-24">
  <div>
      <app-login-socmed></app-login-socmed>
  </div>
</div>

<div class="footer-container">
  <span class="text-btn-underline"
        (click)="cancel()">Cancel
  </span>
  <app-roam-button [label]="'Save'"
                   [class]="'btn-primary'"
  ></app-roam-button>
</div>
