import { Component } from '@angular/core';
import { RoamIconComponent } from '@app/shared/components/roam-icon/roam-icon.component';

@Component({
  selector: 'account-signature',
  standalone: true,
  imports: [RoamIconComponent],
  template: `
    <div class="align-center gap-10 action">
      <roam-icon name="pencil-box" color="grey-dark" size="20"/>
      <roam-icon name="bin" color="grey-dark"/>
    </div>
    <div class="signature-area">
      <img [src]="sampleSignature" alt="signature">
    </div>
  `,
  styles: `
  :host {
    position: relative;
    height: 120px;
    max-height: 120px;
    padding: 1rem;
    border-radius: 8px;
    background: #F5F5F5;
    display: flex;
    justify-content: center;
    align-items: center;

    .action {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
  `
})
export class AccountSignatureComponent {
  public sampleSignature = './assets/sample/signature.png';
}
