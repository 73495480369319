import { Component, input, output } from "@angular/core";
import { ButtonManageComponent } from "@app/shared/components/button/button-manage/button-manage.component";
import { VerifiedStatusComponent } from "@app/shared/components/label-status/verified-status/verified-status.component";

@Component({
  selector: "account-contact-info",
  standalone: true,
  imports: [ButtonManageComponent, VerifiedStatusComponent],
  template: ` 
    <div class="justify-between">
      <h6>{{ contact() }}</h6>
      <button-manage 
        icon="pencil-box" 
        label="Update" 
        (click)="onUpdate.emit()"
      />
    </div>
    <verified-status [status]="status()" />
  `,
  styles: `
    :host {
      padding: 1rem;
      background-color: #f9f9f9;
      border-radius: 8px;
      display: grid;
      gap: 10px;
    }
  `,
})
export class AccountContactInfoComponent {
  contact = input<string>('');
  status = input<string>('unverified');
  onUpdate = output();
}
